define("ember-could-get-used-to-this/initializers/usable-function-manager", ["exports", "ember-could-get-used-to-this/-private/functions"], function (_exports, _functions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(/* application */
  ) {
    // application.inject('route', 'foo', 'service:foo');
  }
  var _default = _exports.default = {
    initialize
  };
});