define("ember-could-get-used-to-this/index", ["exports", "@ember/helper", "@glimmer/tracking/primitives/cache", "ember-could-get-used-to-this/-private/modifiers", "ember-could-get-used-to-this/-private/resources"], function (_exports, _helper, _cache, _modifiers, _resources) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Modifier", {
    enumerable: true,
    get: function () {
      return _modifiers.Modifier;
    }
  });
  Object.defineProperty(_exports, "Resource", {
    enumerable: true,
    get: function () {
      return _resources.Resource;
    }
  });
  Object.defineProperty(_exports, "modifier", {
    enumerable: true,
    get: function () {
      return _modifiers.modifier;
    }
  });
  _exports.use = use;
  function use(prototype, key, desc) {
    let resources = new WeakMap();
    let {
      initializer
    } = desc;
    return {
      get() {
        let resource = resources.get(this);
        if (!resource) {
          let {
            definition,
            args
          } = initializer.call(this);
          resource = (0, _helper.invokeHelper)(this, definition, () => {
            let reified = args();
            if (Array.isArray(reified)) {
              return {
                positional: reified
              };
            }
            return reified;
          });
          resources.set(this, resource);
        }
        return (0, _cache.getValue)(resource);
      }
    };
  }
});