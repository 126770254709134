define("ilm-icons/components/ilm-fa-icon", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isLegacy}}
    <IlmIcon @icon={{this.icon}} @fixedWidth={{@fixedWidth}} ...attributes />
  {{else}}
    <FaIcon
      @icon={{this.icon}}
      @prefix={{this.prefix}}
      @fixedWidth={{@fixedWidth}}
      @rotation={{@rotation}}
      @flip={{@flip}}
      @spin={{@spin}}
      @border={{@border}}
      @pull={{@pull}}
      @transform={{@transform}}
      @symbol={{@symbol}}
      ...attributes
    />
  {{/if}}
  */
  {
    "id": "ghI+/j3y",
    "block": "[[[41,[30,0,[\"isLegacy\"]],[[[1,\"  \"],[8,[39,1],[[17,1]],[[\"@icon\",\"@fixedWidth\"],[[30,0,[\"icon\"]],[30,2]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],[[17,1]],[[\"@icon\",\"@prefix\",\"@fixedWidth\",\"@rotation\",\"@flip\",\"@spin\",\"@border\",\"@pull\",\"@transform\",\"@symbol\"],[[30,0,[\"icon\"]],[30,0,[\"prefix\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9]]],null],[1,\"\\n\"]],[]]]],[\"&attrs\",\"@fixedWidth\",\"@rotation\",\"@flip\",\"@spin\",\"@border\",\"@pull\",\"@transform\",\"@symbol\"],false,[\"if\",\"ilm-icon\",\"fa-icon\"]]",
    "moduleName": "ilm-icons/components/ilm-fa-icon.hbs",
    "isStrictMode": false
  });

  // import { arg } from '@manager-web/common/decorators/arg-types';

  function parseFaIcon(rawIcon) {
    if (!rawIcon) return {};
    const [prefix, icon] = rawIcon.split('.');
    if (!icon) return {
      icon: prefix,
      isLegacy: true
    };
    return {
      prefix,
      icon,
      isLegacy: false
    };
  }
  class IlmFaIcon extends _component2.default {
    get parsedIcon() {
      return parseFaIcon(this.args.icon);
    }
    get isLegacy() {
      return this.parsedIcon.isLegacy;
    }
    get icon() {
      return this.parsedIcon.icon;
    }
    get prefix() {
      return this.parsedIcon.prefix;
    }
  }
  _exports.default = IlmFaIcon;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IlmFaIcon);
});